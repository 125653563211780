@use '@angular/material' as mat;
@import '@angular/material/theming';

/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer base {
  html,
  body {
    font-family: 'Montserrat', sans-serif !important;
  }
  h1 {
    @apply text-3xl;
    @apply text-white;
    @apply mt-6;
    @apply mb-2;
    @apply font-semibold;
  }
  h2 {
    @apply text-2xl;
    @apply text-light-red;
    @apply font-semibold;
    @apply mt-8;
    @apply mb-4; 
  }
  h3 {
    @apply text-xl;
    @apply text-gray;
    @apply mt-2;
    @apply mb-2;
    @apply font-semibold;
  }
  h4 {
    @apply text-xl;
    @apply text-light-red;
    @apply my-4;
    @apply font-semibold;
  }
  p {
    @apply text-base;
  }
}

/* CUSTOMIZATION */

$my-app-primary: mat-palette($mat-red, 600);
$my-app-accent: mat-palette($mat-red, A200);
$my-app-warn: mat-palette($mat-red, 100);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

@include angular-material-theme($my-app-theme);

@include mat.core();

.accent-color {
  color: mat-color($my-app-accent) !important;
}

$mat-white: (
  50: #ffffff,
  100: #000000,
  700: #000000,
  contrast: (
    50: rgba(black, 0.87),
  ),
);

$my-theme: mat-light-theme(
  mat-palette($mat-white, 50),
  $my-app-accent,
  $my-app-warn
);

@include mat.toolbar-theme($my-theme);

@import '~@aws-amplify/ui-angular/theme.css';

[data-amplify-container] {
  display: grid;
  margin-top: 35px;
}

:root {
  --amplify-components-button-primary-background-color: var(
    --amplify-colors-red-60
  );
  --amplify-components-button-primary-hover-background-color: var(
    --amplify-colors-red-40
  );
  --amplify-components-button-primary-focus-background-color: var(
    --amplify-colors-red-40
  );
  --amplify-components-button-primary-active-background-color: var(
    --amplify-colors-brand-secondary-100
  );
  --amplify-components-button-link-color: var(--amplify-colors-red-60);
  --amplify-components-button-link-hover-color: var(--amplify-colors-red-40);
  --amplify-components-button-link-focus-color: var(--amplify-colors-red-40);
  --amplify-components-button-link-active-color: var(
    --amplify-colors-brand-secondary-100
  );
  --amplify-components-button-link-active-background-color: transparent;
  --amplify-components-button-link-focus-background-color: transparent;
  --amplify-components-button-link-hover-background-color: transparent;
  --amplify-components-tabs-item-border-color: var(--amplify-colors-red-60);
  --amplify-components-tabs-border-color: var(--amplify-colors-red-60);
  --amplify-colors-brand-primary-10: var(--amplify-colors-red-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-red-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-red-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-red-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-red-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-red-90);
  --color-light-red: #cf0e10;
  --color-white: #ffffff;
  --color-dark-red: #97000d;
}

span.logo {
  width: 115px;
  height: 54px;
  margin-left: -10px;
  background-image: url('/scss/assets/alo-money.png');
}

#home .logo {
  margin-top: 30px;
  background-image: url('/scss/assets/brandmark-alo-money.png');
  background-repeat: no-repeat;
  background-size: 250px;
  background-position: top center;
  width: 100%;
  height: 150px;
}

#home section button:hover {
  color: white;
  background-color: mat-color($my-app-primary) !important;
}

.headline-1 {
  font-size: x-large;
  color: white;
  background-color: mat-color($my-app-primary) !important;
  padding-left: 10px;
  margin-bottom: 15px;
}

.headline-2 {
  font-size: large;
  color: white;
  background-color: mat-color($my-app-primary) !important;
  padding-left: 10px;
  margin-bottom: 15px;
}

div.mat-mdc-dialog-content table,
div.mat-mdc-dialog-content th,
div.mat-mdc-dialog-content td {
  border: 1px solid gray;
  padding: 5px;
  border-collapse: collapse;
}
hr {
  color: #f5f5f5;
}

.mdc-text-field {
  border-radius: 0px !important;
}

[tuiWrapper][data-appearance='textfield'] {
  box-shadow: none !important;
  background-color: #f5f5f5;
}

[tuiWrapper]:after {
  border: none !important;
}

.zendy-modal .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 16px !important;
}

.zendy-modal .mdc-dialog .mdc-dialog__content {
  padding: 16px !important;
}

.cdk-overlay-dark-backdrop {
  background: #cf0e10 !important;
  opacity: 0.7 !important;
}

.menu-profile-button {
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.25) !important;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(255, 255, 255, 0.5);
}

zendy-intl-phone input {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 24px !important;
  padding: 0px !important;
  font-weight: 600 !important;
  line-height: 32px !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: black !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.04 !important;
}

tui-dropdown .t-content {
  min-width: 36rem !important;
}

@media screen and (max-width: 769px) {
  tui-dropdown .t-content {
    min-width: 100vw !important;
  }
}


.texto-movimiento {
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
}

.animacion-marquesina {
  display: inline-block;
  animation: marquesina 30s linear infinite; /* Ajusta la duración y el tipo de animación según tus necesidades */
}

@keyframes marquesina {
  0% {
    transform: translateX(80%);
  }
  100% {
    transform: translateX(-120%);
  }
}

.alertbg{
  //background-color: #ffcf3d75;
  background-color: #cf0e10;
  color: white;
  //background-color: rgba(255, 217, 102, 0.5)
}

.w-200{
  max-width: 150px;
  height: auto;
}


/* CSS */
.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background:
    linear-gradient(0deg ,rgb(0 0 0/50%) 30%,#0000 0 70%,rgb(0 0 0/100%) 0) 50%/8% 100%,
    linear-gradient(90deg,rgb(0 0 0/25%) 30%,#0000 0 70%,rgb(0 0 0/75% ) 0) 50%/100% 8%;
  background-repeat: no-repeat;
  animation: l23 1s infinite steps(12);
}
.loader::before,
.loader::after {
   content: "";
   grid-area: 1/1;
   border-radius: 50%;
   background: inherit;
   opacity: 0.915;
   transform: rotate(30deg);
}
.loader::after {
   opacity: 0.83;
   transform: rotate(60deg);
}
@keyframes l23 {
  100% {transform: rotate(1turn)}
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.opaque {
  /* Estilos para hacer el contenido opaco */
  background-color: white;// pone toda la pantalla en blanco
  pointer-events: none;
  transition: filter 0.3s ease;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
 
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Fondo blanco semitransparente */
  z-index: 1000; /* Asegura que esté encima de todo */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-popup {
  background-color: #fff; /* Fondo blanco del popup */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Sombra para efecto de elevación */
  display: flex; /* Mostrar como flexbox */
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
}

.scroll-container {
  max-height: 300px;
  overflow-y: auto;
}


$FLAGKIT_FOLDER_PATH: '/assets/flags/svgs/' !default;
$FLAGKIT_COUNTRY_CODES: 'AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BR', 'BS', 'BT', 'BV', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'ER', 'ES', 'ET', 'EU', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB-ENG', 'GB-NIR', 'GB-SCT', 'GB-WLS', 'GB-ZET', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR', 'IS', 'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LGBT', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PW', 'PY', 'QA', 'RE', 'RO', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'UM', 'US-CA', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'XK', 'YE', 'YT', 'ZA', 'ZM', 'ZW';

@mixin flag-background ($size) {
  height: #{$size}px;
  width: #{$size}px;
  background-size: #{$size}px #{$size}px;
}
