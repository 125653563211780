@import "custom";

.toolbar {
    box-shadow: 0 5px 5px -6px rgba(#000, 0.2);
}

body,
html {
    height: 100%;
    margin: 0;
}

h1 {
    padding: 0px;
    margin: 0px;
}

#content {
    min-height: 625px;
}

.warning {
    text-align: center;
    margin: auto;
    padding: 30px;
}

.footer {
    box-shadow: 0px -5px 5px -6px rgba(0, 0, 0, 0.2);
    background: #fff;
    bottom: 0;
    padding: 15px;
    font-size: 11px;
    color: #888;
    width: 90%;
    text-align: center;
    margin: auto;
}

.footer .logo {
    background-image: url("/scss/assets/brandmark-skylink.png");
    background-repeat: no-repeat;
    background-size: 90px;
    background-position: center;
    width: 100%;
    height: 35px;
    margin-bottom: 5px;
}

.container {
    padding: 15px;
    height: 100%;
}

.container-spacer {
    flex: 1 1 auto;
}

.spacer {
    margin-bottom: 100px;
}

.dialpad {
    width: 285px;
    text-align: center;
    margin: auto;
}

.spacer-list-actions {
    margin-left: 15px;
}

.form {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 90%;
}

.alert {
    margin: auto;
    text-align: center;
}

.form table {
    width: 100%;
}

.form .action {
    margin-left: 15px;
}

.form table .actions {
    text-align: right;
}

button span.sub {
    display: block;
    margin-top: -8px;
    margin-bottom: -10px;
    padding: 0px;
    font-size: 12px;
}

.checkout {
    text-align: center;
    margin: auto;
}

mat-form-field {
    width: 100%;
}

#home section {
    text-align: center;
    bottom: 0;
}

#home .welcome {
    margin-top: 15px;
    margin-bottom: 30px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: bold;
}

#home section button {
    height: 100px;
    width: 180px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 5px;
    border: 1px solid rgb(169, 169, 169);
}

.green {
    background-color: #43a047 !important;
    color: white !important;
}

.red {
    background-color: #e53935 !important;
    color: white !important;
}

h1.mat-mdc-dialog-title {
    color: white !important;
    font-size: large !important;
    padding-bottom: 25px;
}

div.mat-mdc-dialog-content {
    padding-top: 30px !important;
}

.check-full-width a{
  text-decoration: underline;
  color: #cf0e10;
}


$FLAGKIT_FOLDER_PATH: './assets/flags/svgs/' !default;
$FLAGKIT_COUNTRY_CODES: 'AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BR', 'BS', 'BT', 'BV', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'ER', 'ES', 'ET', 'EU', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB-ENG', 'GB-NIR', 'GB-SCT', 'GB-WLS', 'GB-ZET', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR', 'IS', 'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LGBT', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PW', 'PY', 'QA', 'RE', 'RO', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'UM', 'US-CA', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'XK', 'YE', 'YT', 'ZA', 'ZM', 'ZW';

@mixin flag-background ($size) {
  height: #{$size}px;
  width: #{$size}px;
  background-size: #{$size}px #{$size}px;
}

.flag {
  @include flag-background('16');

  &.medium {
    @include flag-background('24')
  }

  &.large {
    @include flag-background('32')
  }
}


@each $country-code in $FLAGKIT_COUNTRY_CODES {
  .#{$country-code} {
    background: url(#{$FLAGKIT_FOLDER_PATH}#{$country-code}.svg) no-repeat;
  }
}

